/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/


/* @media screen and (max-width: 600px) { */
    .media-xs #google-map-script {
        width: 100% !important;
    }
/* } */
.gm-style-iw-t{
    bottom: 45px !important;
}