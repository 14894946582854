/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/

.Typogrphy-p {
    margin: auto;
    width: 40%;
}

.wsui-form-control input {
    width: 100%;
    padding: 14px 14px;
    border-radius: 8px;
    border: 1px solid #979797;
}

.wsui-birth-date,
.wsui-birth-date-dob {
    display: flex;
    gap: 17px;
    padding-top: 0px;
    flex-wrap: wrap;
}

.wsui-birth-date-dob .wsui-field,
.wsui-birth-date .wsui-field {
    max-width: 5rem;
    min-width: 3rem;
    flex: 1 1 3rem;
}

.wsui-birth-date-dob .wsui-field:last-child,
.wsui-birth-date .wsui-field:last-child {
    max-width: 8rem;
    min-width: 4rem;
}

.wsui-birth-date .wsui-labelDate.wsui-field-label {
    flex-grow: 1;
    flex-basis: 100%;
    margin-bottom: -1rem;
}

/* @media only screen and (max-width:580px) { */
.media-580-down .date-error-msg {
    position: relative;
    top: -10px;
}
/* } */

.wsui-form-control.wsui-date-control {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.wsui-field-label.date-label {
    text-wrap: nowrap;
    margin-top: 0.65rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
}

.wsui-birth-date .wsui-form-control {
    width: 10%;
}

.wsui-birth-date .wsui-form-control:nth-last-child(1) {
    width: 15%;
}

.wsui-form-control-date input {
    width: 100%;
    padding: 10px;
    font-size: inherit;
    border-radius: 8px;
}

.wsui-form-control input::placeholder {
    font-size: 16px;
}

.wsui-form-control {
    padding: 10px 0;
}

.wsui-form-control-date {
    padding: 18px 0;
}

.wsui-form-control label {
    font-weight: bold;
    font-size: 14px;
}

.wsui-form-control-date label {
    font-weight: bold;
    font-size: 14px;
}

.wsui-text-continue {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    padding: 20px 20px;
}

.wsui-text-continue button {
    width: 20%;
    border-radius: 20px;
}

.wsui-header-partical {
    border: 1px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 15px;
    margin-top: -21px;
    padding-top: 15px !important;
}

.wsui-box-p {
    padding: 16px;
    width: 100%;
    margin: auto;
}

.wsui-form-control .wsui-error {
    color: red;
    font-weight: 600;
    position: relative;
    font-size: 12px;
}

.wsui-form-control-date p {
    color: red;
    font-weight: 600;
}

span.wsui-errorMessage {
    color: red;
    font-weight: bold;
    display: inline-block !important;
    font-size: 12px;
    width: max-content;
}

.wsui-labelDate {
    font-size: 14px;
    font-weight: bold;
}

.wsui-error > span {
    position: absolute;
    left: 0;
    bottom: 100%;
}

.wsui-header-top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid gray;
    padding: 10px;
    gap: 15px;
}

.wsui-dropdowns {
    display: flex;
}

.footer-form {
    display: flex;
    justify-content: center;
}

.wsui-field-label {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 21.79px;
}

input::placeholder,
input[value] {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 13px !important;
}

.insurance-type-title {
    font-size: 25px;
    margin-top: 40px;
}

.styled-input input {
    border: none;
    padding: 13px !important;
}

/* @media only screen and (max-width:620px) { */
.media-620-down .insurance-type-title {
    font-size: 20px;
    margin-top: 30px !important;
}

.media-620-down .patient-name-title {
    display: block;
}

.media-620-down .heading-box {
    padding: 10px 0;
}

.media-620-down .title-name {
    font-size: 16px !important;
}

.media-620-down .MuiTypography-root {
    width: 100%;
}

.media-620-down .MuiTypography-subtitle1 {
    text-align: center;
}

.media-620-down .wsui-birth-date .wsui-form-control {
    width: 19%;
}

.media-620-down .wsui-birth-date .wsui-form-control:nth-last-child(1) {
    width: 25%;
}

.media-620-down .wsui-box-p {
    margin: 0px;
}

.media-620-down .form-div {
    margin: 24px 36px;
}

.media-620-down .wsui-text-continue {
    flex-direction: column-reverse;
}

.media-620-down .wsui-text-continue button {
    width: 100%;
}

.media-620-down .patient-details-wrapper {
    padding: 0px;
}
/* } */

.insuranceForm {
    margin: auto;
    width: 40%;
    min-width: max-content;
}

.insuranceForm > .wsui-dropdowns {
    display: block;
}

.insuranceForm > .wsui-dropdowns > div {
    display: flex;
    gap: 35px;
}
.insuranceForm > .wsui-dropdowns > div .wsui-form-control {
    flex: 1;
}
.insuranceForm > .wsui-dropdowns > div .wsui-form-control > div {
    width: 100%;
}

#fourpc-webscheduler-embed .insuranceForm > .wsui-dropdowns > div input.MuiAutocomplete-input {
    /* Switching to #fourpc-webscheduler-embed increased the specificity
       of the border-box on *, overriding the content-box on the mui dropdown */
    box-sizing: content-box;
}

.insuranceForm .insurencedob {
    display: flex;
    gap: 10px;
}

.insuranceForm .insurencedob > span {
    width: 20%;
}

.footerdata {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-top: 10px;
}

.footerdata .footer-form {
    display: block;
    padding-top: 10px;
}

.footerdata .wsui-text-continue {
    display: block;
    width: 30%;
}

.footerdata .wsui-text-continue > button {
    width: 100%;
}

.inputAlign > div {
    width: 352px;
}

.inputAligns > div {
    width: 740px;
}

.p-width {
    width: 48%;
}

.inputdetails > div > div > div {
    padding: 0px !important;
    border-radius: 30px;
}

.inputdetails > div > div label {
    top: -7px;
}
.border-radius input {
    border-radius: 24px !important;
}
.input-borders input {
    border: 1px solid #d6d6d6;
}

.inputName {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
    border: none;
}

/* @media only screen and (min-width:621px) and (max-width:900px) { */
.media-621-900 .inputdetails > div {
    max-width: 95vw;
}

.media-621-900 .multiple-appointment-page {
    min-width: max-content;
}
/* } */

/* @media only screen and (max-width:620px) { */
.media-620-down .wsui-dropdowns > div {
    display: block !important;
}

.media-620-down .insuranceForm {
    margin: auto;
    width: 90%;
    min-width: 100%;
}

.media-620-down .footerdata {
    display: block;
}

.media-620-down .footerdata .footer-form {
    text-align: center;
}

.media-620-down .footerdata > .wsui-text-continue {
    width: 100%;
}

.media-620-down .wsui-header-top {
    display: block;
    text-align: center;
}

.media-620-down .inputAlign > div {
    width: 100%;
}
.media-620-down .inputAligns > div {
    width: 100%;
}
.media-620-down .p-width {
    width: 100%;
}
/* } */

/* multiple appointments page */
.multiple-appointment-page {
    margin: auto;
    width: 60%;
    /* min-width: max-content; */
}

.wsui-header-top-m {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
}

.wsui-icon {
    padding: 0.5rem;
    border: 1px solid gray;
    background-color: #d8d8d8;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wsui-icon > svg {
    width: 5rem;
    height: 100%;
}

.wsui-header-text {
    width: fit-content;
}

.wsui-header-text h2,
.wsui-header-text p {
    margin: 0px auto;
}

.input-cb {
    border: 1px solid grey;
    border-radius: 2rem;
    padding: 10px;
    color: hsl(202.14deg 97.91% 37.45%);
}

.border-right {
    border-right: 1px solid grey;
}

.wsui-footer-data {
    display: flex;
    justify-content: end;
    border-top: 1px solid grey;
    align-items: center;
    border-bottom: 1px solid grey;
    margin-top: 10px;
}

.mutiple-appointment-label .wsui-mainPage > div {
    display: flex;
    column-gap: 100px;
    align-items: center;
}

.mutiple-appointment-label .appointmentDetials {
    width: 20%;
    text-align: end;
    margin: 0;
    padding: 5px 0;
}

.appointmentDetials + p {
    margin: 0;
}

.mutiple-appointment-label {
    position: relative;
    border-bottom: 1px solid grey;
}

.mutiple-appointment-label .appointmentBtn {
    position: absolute;
    left: 83%;
    top: 32%;
}

.appointmentBtn > button {
    display: flex;
    align-items: center;
}

.mutiple-appointment-label::after {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 83%;
    top: 11px;
    left: 27%;
}

.mutiple-appointment-label::before {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 83%;
    top: 11px;
    right: 22%;
}

.wsui-mainPage {
    padding: 20px 0;
}

.wsui-borderBotton {
    border-bottom: none !important;
}

.wsui-text-continued {
    display: flex;
    justify-content: end;
}

.wsui-text-continued p {
    width: 65%;
    text-align: end;
    padding-right: 15px;
}

/* @media only screen and (max-width:620px) { */
.media-620-down .multiple-appointment-page {
    margin: 0px;
    width: 100%;
}

.media-620-down .mutiple-appointment-label::after,
.media-620-down .mutiple-appointment-label::before {
    position: initial;
    border: none;
}

.media-620-down .mutiple-appointment-label .wsui-mainPage > div {
    display: block;
}

.media-620-down .wsui-mainPage {
    padding: 10px 30px !important;
}

.media-620-down .appointmentDetials {
    text-align: start !important;
    width: 100% !important;
}

.media-620-down .mutiple-appointment-label .appointmentBtn {
    position: initial;
    display: flex;
    justify-content: end;
}

.media-620-down .mutiple-appointment-label {
    border-bottom: 1px solid gray;
    padding-bottom: 30px;
}

.media-620-down .wsui-footer-data {
    display: block;
    border-top: 1px solid grey;
    align-items: center;
    border-bottom: none;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.media-620-down .wsui-text-continued {
    display: flex;
    justify-content: end;
}

.media-620-down .wsui-text-continued p {
    width: 100%;
    text-align: center;
    padding-right: 0px;
}

.media-620-down .wsui-footer-data {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
}
/* } */
/* appointmentvisitdetails */
.visited-appointment-page {
    margin: auto;
    padding-top: 20px;
}
.media-720-up .visited-appointment-page {
    width: 60%;
}

.wsui-footer-details {
    padding: 0px 10px;
}

.wsui-box {
    border: 1px solid grey;
    padding: 1.5rem;
    border-radius: 15px;
    margin-top: 5px;
    margin-bottom: 50px;
}

.wsui-inner-box {
    padding: 40px;
}

.visited-appointment-label .wsui-mainPage > div {
    display: flex;
    column-gap: 100px;
}

.visited-appointment-label .appointmentDetials {
    width: 20%;
    text-align: end;
    margin: 0;
    padding: 5px 0;
    flex-grow: 1.3;
}

.visited-appointment-label .wsui-patient-info {
    flex: 3;
    text-wrap: balance;
}

.visited-appointment-label {
    position: relative;
}

.visited-appointment-label .appointmentBtn {
    position: absolute;
    left: 85%;
    top: 25%;
}

.visited-appointment-label
    .wsui-mainPage
    > div:first-child
    > p.wsui-heading-title::after,
.confirmation-appointment-label
    .wsui-mainPage
    .wsui-confirmation-grid-flex
    > p:first-child::after {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 80%;
    top: 1rem;
    margin-left: 3rem;
}
.confirmation-appointment-label
    .wsui-mainPage
    .wsui-confirmation-grid-flex
    > p:first-child::after {
    height: 95%;
    margin-left: 2rem;
}

.visited-appointment-label::before {
    content: "";
    position: absolute;
    border: 1px solid gray;
    height: 70%;
    top: 11px;
    right: 20%;
}

.back-footer {
    color: #000;
    background-color: #fff;
    margin-right: 10px;
}

/* @media only screen and (max-width:620px) { */
.media-620-down .mutiple-appointment-label .appointmentBtn {
    justify-content: center;
}

.media-620-down .visited-appointment-page {
    margin: 0px;
    width: 100%;
}

.media-620-down .wsui-box {
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.media-620-down .visited-appointment-label {
    margin: 0px;
    width: 100%;
}

.media-620-down p.wsui-heading-title::after,
.media-667-down .wsui-confirmation-grid-flex > p:first-child::after {
    display: none;
}

.media-620-down .visited-appointment-label .wsui-mainPage > div {
    display: block;
}

.media-620-down .visited-appointment-label .appointmentBtn {
    position: initial;
    display: flex;
    justify-content: end;
    padding-right: 30px;
}

.media-620-down .visited-appointment-label {
    border-bottom: 1px solid gray;
}

.media-620-down .wsui-footer-details {
    display: flow-root;
}
/* } */

.visited-appointment-page .wsui-header-text {
    border-bottom: 1px solid gray;
    line-height: 14px;
    padding-bottom: 25px;
}

.visited-appointment-page .wsui-header-text p:not(.appointment-details-header) {
    margin: 1rem;
    text-wrap: pretty;
}

.visited-appointment-label::before {
    border: none;
}

.wsui-header-text-mobile h2 {
    display: none;
}

/* .wsui-footer-content {
    display: none;
  } */

/* @media only screen and (max-width:667px) { */
.media-667-down .visited-appointment-page .wsui-header-text {
    line-height: 26px;
}

.media-667-down .visited-appointment-page .wsui-header-top-m {
    padding-bottom: 16px;
    margin-top: 5px;
    flex-direction: column-reverse;
    padding-top: 0px;
}

.media-667-down .visited-appointment-page .wsui-icon-text {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.media-667-down .visited-appointment-page .wsui-header-text {
    border-bottom: none;
    width: 100%;
}

.media-667-down .visited-appointment-label {
    border-bottom: none;
}

.media-667-down .visited-appointment-page .wsui-header-text-mobile h2 {
    display: block;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.media-667-down .visited-appointment-label .wsui-mainPage :nth-child(3) {
    display: none;
}

.media-667-down .wsui-footer-content {
    display: block;
    padding: 5px;
}

.media-667-down .visited-appointment-page .wsui-header-text p {
    margin-bottom: 11px;
}

.media-667-down .visited-appointment-page .wsui-icon > svg,
.media-667-down .confirmation-appointment-page .wsui-icon > svg {
    width: 3rem;
}
/* } */

.wsui-get-directions-link {
    display: none;
}
.wsui-map-container {
    display: block;
}

/* @media only screen and (max-width:1024px) { */
.media-md-down .wsui-get-directions-link {
    display: block;
}
.media-md-down .wsui-map-container {
    display: none;
}
/* } */
