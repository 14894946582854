/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/

.bg-Black{
    background-color: #000;
    color: #fff;
    text-align: center;
    margin-top: 30px;
}

.bg-white {
    padding: 4px 24px;
    background-color: #fff;
    color: #2065d1;
}

.confirmation-appointment-page .wsui-footer-data button {
    padding: 4px 24px;
}

.bg-white:hover {
    padding: 7px 24px;
    background-color: #fff;
    color: #2065d1;
}

.appointment-icon svg path {
    fill: #fff;
}

.wsui-main-heading {
    color: red;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
}

.wsui-details-box {
    padding: 20px 10px;

}

.appointment-details-header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 34.05px;
}

.wsui-appointment-details-header-text {
    justify-content: space-evenly  !important;
    column-gap: 100px!important;
}

.appointment-details-subheader {
    font-size: 16px;
    line-height: 21.79px;
}

.confirmation-appointment-page .appointment-icon {
    /* height: 70px;
    width: 70px; */
    margin: 0;
}

.wsui-details-container {
    padding-left: 10px!important;
    padding-bottom: 10px!important;
}

.wsui-confirmation-grid-header,
.wsui-confirmation-grid-flex {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
}
.wsui-confirmation-grid-header,
.wsui-confirmation-grid-flex {
    column-gap: 4em;
}
.wsui-confirmation-grid-header>.appointment-icon-container,
.wsui-confirmation-grid-flex>.wsui-details-heading {
    justify-self: right;
}
.wsui-confirmation-grid-flex p {
    text-wrap: pretty;
}
.wsui-confirmation-grid-header {
    align-items: center;
}


.wsui-details-heading {
    margin-bottom: 0px!important;
    font-weight: 700;
    line-height: 21.79px;
    margin-top: 10px!important;
}

.wsui-button-box {
    text-transform: capitalize;
}

.confirmation-appointment-page  .wsui-header-text {
    margin-left: 26px;
}

.confirmation-appointment-page .wsui-box  {
    padding: 0px;
}

.confirmation-appointment-page {
    margin: auto;
    width: 60%;
}

.wsui-footer-details {
    padding: 17px 20px;
}

.wsui-box {
   border: 1px solid grey;
   padding: 1.5rem;
   border-radius: 15px;
   margin-top: 24px;
   margin-bottom: 50px;
}

.wsui-inner-box {
    padding: 40px;
}

/* .confirmation-appointment-label .wsui-mainPage > div {
    display: flex;
    column-gap: 100px;
}
.confirmation-appointment-label .wsui-mainPage > div > .confirmation-detail-value {
    flex: 1;
} */

.confirmation-appointment-label .confirmationetials {
    /* width: 24%; */
    text-align: end;
    margin: 0;
    padding: 5px 0;
}

.confirmation-appointment-label {
    position: relative;
}

.confirmation-appointment-label .appointmentBtn {
    position: absolute;
    left: 85%;
    top: 25%;
}

.back-footer {
    color: #000;
    background-color: #fff;
    margin-right: 10px;
}

.confirmation-appointment-page .wsui-header-text {
    margin-left: 47px;
}

.wsui-footer-icon {
    display: flex;
    border-left: 1px solid gray;
    align-items: center;
    gap:6px;
    margin:0px 10px;
    padding: 0px 10px;
}

.wsui-footer-calender {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wsui-footer-calender .wsui-calendar-button {
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wsui-footer-calender .wsui-calendar-button svg {
    font-size: 1.8rem;
}

/* @media only screen and (max-width:620px) { */
    .media-667-down .wsui-appointment-details-header-text {
        column-gap: 0px!important;
        margin-left: 0px!important;
    }

    .media-667-down .confirmation-appointment-page{
        margin: 0px;
        width: 100%;
    }

    .media-667-down .wsui-box{
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
     }

     .media-667-down .confirmation-appointment-label{
        margin: 0px;
        width: 100%;
    }

    .media-667-down .confirmation-appointment-label::after ,
    .media-667-down .confirmation-appointment-label::before {
        position: initial;
        border: none;
    }

    /* .media-667-down .confirmation-appointment-label .wsui-mainPage > div {
        display: block;
    } */

    .media-667-down .confirmation-appointment-label .appointmentBtn {
        position: initial;
        display: flex;
        justify-content: end;
        padding-right: 30px;
    }

    .media-667-down .confirmation-appointment-label {
        border-bottom: 1px solid gray;
    }

    .media-667-down .wsui-footer-details {
        display: flow-root;
    }
/* } */

.confirmation-appointment-page .wsui-header-text {
    padding-bottom: 10px;
}

.confirmation-appointment-page .wsui-icon> svg {
    transform: rotate(-22deg);
}

.confirmation-appointment-page .wsui-header-text p {
    margin-bottom: 15px;
}

.confirmation-appointment-page .wsui-header-top-m {
    border-bottom: none !important ;
}

.confirmation-appointment-page .wsui-header-top-m .wsui-icon {
    padding: 50px;
}

/* .confirmation-appointment-page .wsui-inner-box {
    margin-left: 16%;
} */

.confirmation-appointment-label::after {
    left: 41%;
}

.confirmation-appointment-label::before {
    border: none;
}

 .wsui-header-text-mobile h2 {
  display: none;
}

/* .wsui-footer-content {
    display: none;
} */
.wsui-footer-content {
    display: block;
    padding: 25px;
}

.wsui-head-title {
    margin-top: 5px!important;
}
.confirmation-height {
    margin-top: 16px !important;
    padding: 0 !important;
}
.confirmation-height p {
    margin: 0;
}



/* @media only screen and (max-width:667px) { */
    .media-667-down .wsui-confirmation-grid-flex {
        display: flex;
        flex-direction: column;
    }
    .media-667-down .confirmation-detail-value,
    .media-667-down .wsui-details-heading,
    .media-667-down .confirmation-height,
    .media-667-down .confirmation-appointment-label {
        margin: 0 !important;
        margin-top: 0 !important;
        padding: 0;
    }

    .media-667-down .wsui-confirmation-grid-header {
        column-gap: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .media-667-down .wsui-details-heading {
        margin-top: 16px !important;
    }

    .media-667-down .confirmation-appointment-page .wsui-header-texts {
        padding: 0px 10px;
        margin-top: 10px;
      
    }
    .media-324-down .confirmation-appointment-page .wsui-header-texts {
        font-size: medium;
    }
    .media-667-down .confirmation-appointment-page .wsui-header-top-m {
        padding-top: 43%;
        position: relative;
        padding-bottom: 16px;
        margin-top: 5px;
    }
  
    .media-667-down .confirmation-appointment-label .wsui-mainPage .wsui-header-text {
        display: flex;
        margin-left: 14px;
         gap: 19px;
    }

    .media-667-down .wsui-box  {
        margin-bottom: 5px;
        border: 1px solid gray;
        margin: auto;
        width: 87%;
    }

    .media-667-down .confirmation-appointment-label {
        border-bottom:none;
    } 

    .media-667-down .confirmation-appointment-page .wsui-header-text-mobile h2 {
        display: block;
        font-size: 22px;
        font-weight: 700;
    }

    /* .media-667-down .confirmation-appointment-label .wsui-mainPage :nth-child(3) {
        display: none;
    } */

    .media-667-down .wsui-footer-content {
        display: block;
        padding: 5px;
    }
      
    .media-667-down .confirmation-appointment-page .wsui-header-text p {
        margin-left: 5%;
    }

    .media-667-down .confirmation-appointment-label .confirmationetials {
        margin-bottom: 15px;
    }

    /* .media-667-down .confirmation-appointment-label .wsui-mainPage > div {
        display: block;
        margin-bottom: 10px;
        line-height: 0px;
    } */

    .media-667-down .confirmation-height {
        line-height: 19px;
    }

    .media-667-down .confirmation-appointment-label .confirmationetials {
        width: fit-content;
        margin-bottom: 0px;
    }

    .media-667-down .confirmation-appointment-page .wsui-inner-box {
        margin-left: 8px;
    }

    .media-667-down .wsui-footer-calender {
        /* padding-left: 50%; */
        position: relative;
        margin: auto;
        width: 75%;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .media-667-down .wsui-footer-icon {
        margin-right: 0;
        padding-right: 0;
    }

    .media-667-down .wsui-footer-icon p {
        /* position: absolute;
        top: -14px;
        left: 0; */
        font-size: 14px;
    }

    .media-667-down .wsui-footer-details {
        margin: auto;
    }

    .media-667-down .confirmation-appointment-page .wsui-footer-data button .bg-white {
        padding: 4px 25px;
        background-color: #fff;
        color: #2065d1;
    }

    .media-667-down .confirmation-appointment-page .wsui-footer-data button {
        padding: 4px 24px;
    }
/* } */

/* @media only screen and (min-width:768px) and (max-width:900px) { */
    .media-768-900 .confirmation-appointment-page {
        width: 80%;
    }
    .media-768-900 .confirmation-appointment-page .wsui-inner-box {
        margin-left: 4%;
    }
    .media-768-900 .confirmation-appointment-page .header-texts {
       margin-top: 10px;
    }
/* } */

/* @media only screen and (min-width:901px) and (max-width:1024px) { */
    .media-901-1024 .confirmation-appointment-page {
        width: 80%;
    }
    .media-901-1024 .confirmation-appointment-page .wsui-inner-box {
        margin-left: 8%;
    }
    .media-901-1024 .confirmation-appointment-page .header-texts {
        margin-top: 10px;
     }
/* } */

/* @media only screen and (max-width:324px) { */
    .media-324-down .confirmation-appointment-label .wsui-mainPage .wsui-header-text {
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .media-324-down .confirmation-detail-value {
        line-height: normal;
    }

    .media-324-down .confirmation-detail-value-email {
        word-break: break-all;
    }
/* } */
