/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/

/*
THIS IS A CSS MODULES FILE
The build process will automatically change these class names to make them globally unique.
See AppointmentOneOnlyModal.tsx to see how CSS classes from this type of file are consumed. The file is imported at the top and the classes are its members.
Do not directly reference these classes as string literals, it will not work.
*/

.root {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel {
  background-color: white;
  border-radius: 20px;
  padding: 3em 1em 2em 1em;
}

:global(.media-xs) .panel {
  max-width: 90vw;
}
:global(.media-sm) .panel {
  max-width: 75vw;
}
:global(.media-md) .panel {
  max-width: 65vw;
}
:global(.media-lg) .panel {
  max-width: 50vw;
}
:global(.media-xl) .panel {
  max-width: 40vw;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  padding-top: 1em;
  border-top: 1px solid gray;
  margin-top: 1em;
}

.goBackButton,
.confirmButton {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  padding: 0.7em 1em;
  border-radius: 2000px;
}

.goBackButton:disabled,
.confirmButton:disabled {
  opacity: 0.3;
  cursor: default;
}

.goBackButton {
  display: inline-flex;
  align-items: center;
  gap: 0.1em;
  border: 1px solid gray;
}
.goBackButton>svg {
  width: 1em;
  height: 1em;
}

.confirmButton {
  color: white;
  background-color: #0278BD;
}

.bold {
  font-weight: bold;
}

