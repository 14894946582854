/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/

.Typogrphy-p {
    margin: auto;
    width: 40%;
}

.Typogrphy-pb {
    margin: auto;
    font-size: 20px;
    text-align: center;
}

.wsui-form-control input {
    width: 100%;
    padding: 14px 14px;
    border-radius: 8px;
}

.wsui-dob {
    display: flex;
    align-items: flex-start;
    gap: 17px;
    padding: 0;
}

.wsui-form-control-date  input {
        width: 100%;
        padding: 10px;
        font-size: inherit;
        border-radius: 8px;
}

.wsui-form-control input::placeholder {
    font-size: 16px;
}

.wsui-form-control {
    padding: 10px 0;

}

.wsui-form-control-date {
    padding: 18px 0;
}

.wsui-form-control label {
    font-weight: bold;
    font-size: 18px;
}

.wsui-form-control-date label {
    font-weight: bold;
    font-size: 18px;
}

.wsui-text-continue {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20PX;
    padding: 20px 20px;
}

.wsui-text-continue button {
    width: 20%;
    border-radius: 20px;
}

.wsui-header-partical {
    border: 1px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 10px;
    margin-top: -37px;
}

.wsui-form-control .wsui-error {
    color: red;
    font-weight: 600;
    position: relative;
}

.wsui-form-control-date p {
    color: red;
    font-weight: 600;
}

.wsui-labelDate {
    font-size: 18px;
    font-weight: bold;
}

.wsui-error > span {
    position: absolute;
    left: 0;
    bottom: 100%;
}

.wsui-parent-control {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 20px;
}

.city-details {
    width: 50%;
}

.state-details {
    width: auto;
}

.form-city {
    width: 65%;
}

.desktop-city {
    width: auto;
}

.form-state {
    width: 30%;
}

.form-zip {
    width: 25%;
}

.form-country{
    width: 60%;
}

.form-homePhone {
    width: 48.5%;
}

.form-mobilePhone {
    width: 49%;
}

.wsui-disabled-input {
    background-color: darkgray;
}

/* undo user agent styles */
.state-details>select.wsui-disabled-input {
    opacity: 1;
    color: initial;
}

.wsui-main-heading-title {
    font-size: 1.25rem;
}

input::placeholder, input[value], .MuiSelect-select {
    font-family: "Roboto","Helvetica","Arial",sans-serif!important;
    font-size: 13px!important;
    /* color: red; */
}

/* @media only screen and (min-width:621px) { */
    .media-621-up .wsui-error > .validation-error {
        top: -21px;
        position: absolute;
        right: 0;
       
    }
/* } */


/* @media all and (max-width: 620px) { */
    .media-620-down .demographic-form-container {
        margin-top: 15px;
    }

    .media-620-down .form-city {
        width: 100%;
    }

    .media-620-down .city-details {
        width: auto;
    }

    .media-620-down .form-state {
        width: 30%;
    }

    .media-620-down .form-zip {
        width: 21.5%;
    }

    .media-620-down .form-country {
        width: 33.5%;
    }

    .media-620-down .form-homePhone {
        width: 45%;
    }

    .media-620-down .form-mobilePhone {
        width: 47%;
    }

    .media-620-down .Typogrphy-p {
        width: 100%;
    }

    .media-620-down .wsui-text-continue button {
        width: 100%;
        border-radius: 20px;
    }
/* } */

/* @media only screen and (min-width:600px) and (max-width:880px) { */
    .media-600-880 .Typogrphy-p {
        width: 100%;
    }
/* } */
/* @media only screen and (min-width:881px) and (max-width:1350px) { */
    .media-881-1350 .Typogrphy-p {
        width: 60%;
    }
/* } */

.errormessage{
    color: red;
    font-weight: bold;
    font-size: smaller;
    text-align: right;
    vertical-align: text-bottom;
    padding-bottom: 0px;
}