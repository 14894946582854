/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/


.wsui-header-partical {
    border: 2px solid #d6d6d6;
    border-radius: 28px;
    padding: 25px 20px 15px;
    margin-top: -24px;
}

.wsui-top-heading {
    font-size: 18px;
}

.wsui-box-p {
    margin: 0px;
}

.appointmentBtn .input-cb {
    cursor: pointer;
    column-gap: 0.5rem;
    padding: 0.5rem 0.8rem;
}
.appointmentBtn .input-cb input[type="checkbox"] {
    cursor: pointer;
}

p.appointment-changes {
    text-align: center;
}

.wsui-header-top-m {
    padding-top: 1rem;
}

.wsui-footer-data {
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
}

.wsui-footer-data button {
    padding: 4px 19px;
}

.heading-subText {
    display: inline ;
}

.mutiple-appointment-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 34.05px;
    display: inline-flex;
}

.booking-icon div.wsui-icon {
    width: 101px!important;
    height: 101px!important;
}

.wsui-submit-button {
    text-transform: capitalize;
    padding: 0px 30px;
    min-width: max-content;
}

/* @media only screen and (min-width:668px) { */
    .media-668-up .mutiple-appointment-subheader {
        display: none;
    }

    .media-668-up .mutiple-appointment-subheader-desktop {
        display: block;
        font: caption;
        margin-top: 15px!important;
    }

    .media-668-up .mutiple-appointment-sub {
        margin-top: 15px!important;
        margin-left: 15px!important;
    }

    .media-668-up .existing-appointment {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24.51px;
    }
/* } */

/* @media only screen and (max-width:667px) { */
    .media-667-down .wsui-header-partical {
        display:block;
        text-align: center; 
        padding: 34px 0px 14px;
    }

    .media-667-down .wsui-top-heading {
        font-size: 18px;
        font-weight: 400;
        line-height: 24.51px;
    }

    .media-667-down .wsui-header-top {
        font-size: 16px;
    }

    .media-667-down .heading-subText {
        display: block;
    }

    .media-667-down .mutiple-appointment-header {
        font-weight: 700;
        font-size: 22px;
        line-height: 29.96px;
        display: inline-flex;
    }

    .media-667-down .mutiple-appointment-subheader {
        text-align: center;
        margin-top: 10px!important;
    }

    .media-667-down .mutiple-appointment-subheader-desktop {
        display: none;
    }

    .media-667-down .existing-appointment {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 21.79px;
    }

    .media-620-down .wsui-mainPage > div:not(:last-child){
        margin-bottom: 15px;
    }

    .media-667-down .appointment-changes {
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 21.79px;
        padding: 0px 2px;
    }

    .media-667-down .MuiBox-root .css-i9gxme {
        border-bottom: 1px solid gray; 
        padding: 0px 0px 6px 0px;
        margin-bottom: 5px ;
    }
    .media-667-down .wsui-header-partical {
        padding-top: 8px!important;
        padding-bottom: 5px;
    }

    .media-667-down .wsui-header-text {
        position: relative;
    }

    .media-667-down .wsui-header-text h4 {
        position: absolute;
        left: -50px;
    }

    .media-667-down .wsui-header-top-m {
        padding: 29px 0px 25px;
    }
    
    .media-667-down .mutiple-appointment-sub {
        margin-left: 5%;
    }

    .media-667-down .booking-icon div.wsui-icon {
        width: 68px!important;
        height: 68px!important;
    }

    .media-667-down .booking-icon svg {
        width: 50px!important;
    }
/* } */
