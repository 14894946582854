/*
NO MEDIA QUERIES
This app is designed to be able to be embedded in another page without an iframe. This means we cannot use media queries for responsive design.
Instead, we use media classes that are dynamically added to and removed from the root of the app as it is resized. These classes all begin with ".media".
Use these classes with a child selector to handle responsive design.
The complete list of them can be found in the method useMediaClasses in src\theme\index.tsx. If new ones need to be added, add them there.
*/

.wsui-main-heading {
    color: red;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
}

.wsui-details-box {
    padding: 20px 10px;
}

.appointment-details-header {
    font-weight: 700;
    font-size: 25px;
    line-height: 34.05px;
    text-align: left;
}

.appointment-details-subheader {
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
}

.wsui-details-container {
    padding-left: 10px!important;
    padding-bottom: 0px!important;
}

.wsui-details-heading {
    margin-bottom: 0px!important;
    font-weight: 700;
    line-height: 21.79px;
    margin-top: 10px!important;
}

.wsui-patient-info {
    font-weight: normal!important;
    padding: 5px 0;
}

.wsui-button-box {
    text-transform: capitalize;
}

.wsui-heading-title {
    margin-top: 0px!important;
    font-weight: 700;
}

.wsui-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
}

.wsui-confirm-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.8rem 0;
}

.confirm-message-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
    row-gap: 10px;
}

/* @media only screen and (min-width:720px) { */
    .media-720-up .wsui-details-container {
        padding-top: 10px!important;
    }

    .media-720-up .wsui-footer-details {
        padding: 10px 10px 10px 0;
    }

    .media-720-up .wsui-button-box {
        padding: 0px 23px;
    }
/* } */
